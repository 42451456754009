@import 'reset';
@import 'variables';

/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-weight: 300;
  font-style: normal;

  src: url('../fonts/ubuntu-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
       url('../fonts/ubuntu-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ubuntu-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ubuntu-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ubuntu-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ubuntu-v14-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-weight: 700;
  font-style: normal;

  src: url('../fonts/ubuntu-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
       url('../fonts/ubuntu-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ubuntu-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ubuntu-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ubuntu-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ubuntu-v14-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

body {
  min-width: 320px;

  transition: $base-transition;
  // color: $base-font-color;
  // background-color: $base-background-color;

  font-family: $font-family-base;
  font-size: $base-font-size;
  font-weight: $flight;
  line-height: $base-line-height;

  -webkit-font-smoothing: antialiased;
}

header .wrapper {
  position: relative;

  max-width: 100%;
  margin: 0;
  padding: 0;

  .content-wrapper {
    float: right;

    width: 100%;
  }
}

.sidebar {
  position: fixed;

  float: left;

  width: 420px;
  height: 100%;
  margin-right: -320px;

  text-align: center;

  color: #fff !important;
  background-image: linear-gradient(to top, #1680ed 0%, #4da02b 100%);
  box-shadow: 4px 0 5px -2px rgba(94, 94, 94, 0.81);

  .bio {
    position: absolute;
    top: 25%;
    left: 5%;

    display: block;

    width: 90%;
    margin: 0 auto;
    .avatar {

      margin-bottom: 1em;
    picture,
        img {
        width: 140px;

        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
      }
    }
    .title {
      h1 {
        margin-bottom: 0;

        text-transform: uppercase;

        font-size: 28px;
        font-weight: 700;
      }
      h2 {
        margin: 0 0 30px 0;

        letter-spacing: 1.6px;

        font-size: 20px;
        font-weight: 300;
      }
    }



    .desc {
      display: block;

      width: 85%;
      margin: 0 auto;

      p {
        color: #f2f2f2;

        font-size: 15px;
        font-weight: 300;
      }
    }

    .social {
      margin-top: 30px;

      a {
        transition: all .2s linear;

        color: rgba(255, 255, 255, 0.7);

        &:hover,
        &:focus {
          color: #fff;
        }
      }

      i {
        font-size: 28px;
      }

      ul {
        margin: 0;
        padding: 0;

        list-style: none;

        li {
          display: inline-block;

          margin-right: 5px;
        }
      }
    }
  }
}

.content {
  position: relative;

  clear: both;
  overflow: auto;

  margin-left: 420px;
  padding: 30px 0 0 60px;

  color: #777;

  .title {
    padding-bottom: 20px;
    i {
      float: left;

      margin-right: 15px;

      color: #233f5d;

      font-size: 26px;
    }

    h2 {
      color: #555;
      font-size: 1.5em;
      font-weight: 700;
      margin-bottom: 1em;
    }

    .item {
      margin-bottom: 20px;

      h3 {
        margin-bottom: 5px;
        font-size: 1em;
        color: #777;
        font-weight: 700;
      }

      span {
        color: rgba(35, 63, 93, 0.9);
        font-weight: 300;
      }

      p {
        width: 95%;
        margin-top: 0;

        letter-spacing: 1.1px;

        line-height: 25px;
      }
    }
    ul {
      list-style: disc;
      margin-left: 20px;
    }
    li {
      margin-bottom: 8px;
    }

    .w33 {
      display: inline-block;

      width: 30%;
    }
    .span-skills {
      display: inline-block;
      margin-right: 7px;
      font-weight: 500;
    }

  }
}

.skills {
  position: relative;

  width: 90%;
  height: 10px;
  margin-top: 10px;
  padding: 6px;

  border-radius: 50px;
  background: #233f5d;

  span {
    position: relative;

    display: block;
    overflow: hidden;

    height: 100%;

    border-top-left-radius: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 20px;
    background-color: #1687ed;
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);

    &:after {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      overflow: hidden;

      content: "";
      animation: move 4s linear infinite;

      background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
      background-size: 50px 50px;
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    position: relative;

    width: 100%;
    margin-right: 0;
    padding-top: 60px;
    padding-bottom: 60px;

    box-shadow: none;

    .bio {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .content {
    position: relative;

    margin-left: 0;
    padding: 20px;

    .title {
      .item {
        h3 span {
          display: block;
        }

        p {
          width: 100%;

          text-align: justify;
        }
      }

      .skills {
        width: 100%;
        margin-top: 0;
      }

      .w33 {
        display: inline-block;

        width: 95%;
      }
    }
  }
}