// Font Sizes
$base-font-size: 16px;
$em-base: $base-font-size;
$font-family-base: 'Ubuntu', sans-serif;
$font-ubuntu: 'Ubuntu', sans-serif;
$font-monotype: 'Monotype Corsiva', cursive;
$font-algerian: 'Algerian', fantasy;
$heading-font-family: $font-family-base;

// Colors
$blue: #477dca;
$blue-light: #f4f4f4;
$blue-gray: #607d8b;
$blue-dark: #606060;
$black: #151515;
$gray: #909090;
$gray-dark: #252525;
$gray-medium: #999;
$gray-light: #ebebeb;
$red: #f04243;

$orange: orange;

// font weight
$fubold: 900; // Extra Black, Fat, Poster or Ultra Black
$fblack: 800; // Black, Extra Bold or Heavy
$fbold: 700; // Bold
$fsbold: 600; // Semibold, Demibold
$fmed: 500; //  Medium
$fnorm: 400; //  Normal or Regular
$flight: 300; // Book or Demi
$fthin: 200; // 200 Light or Thin
$fulight: 100; // 100 Extra Light or Ultra Light

// Line height
$base-line-height: 1.2;
$heading-line-height: 1.2;

// Other Sizes
$width: 980px;
$footer: 50px;
$footer_margin: $footer - 2 * $footer;
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;


// Font Colors
$base-font-color: $gray-dark;
$action-color: $blue-gray;

// Border
$base-border-color: $gray-light;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Box Shadows
// $base-box-shadow: 0 1px 3px rgba(#000, .06);
// https://www.cssmatic.com/box-shadow
$base-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.25);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, .06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
$base-transition: background-color $base-duration * 5 $base-timing, color $base-duration * .7 $base-timing, opacity $base-duration * .3 $base-timing;

.flex-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
